(function () {
  'use strict';

  /**
   * @ngdoc filter
   * @name trek.filter:cents
   *
   * @description
   *
   * @param {Array} input The array to filter
   * @returns {Array} The filtered array
   *
   */
  angular
    .module('trek')
    .filter('cents', cents);

  function cents() {
    return function (input) {
      return Math.round(input * 100);
    };
  }
}());
